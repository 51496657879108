<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2020-12-31 15:34:00
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-05 17:52:06
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : handle === 'edit' ? '编辑' : '查看'" style="top: 8px;" width="80%" v-model="visible" :footer="null" :maskClosable="false">
        <a-row :gutter="8">
            <a-col :span="6">
            <a-tree
                checkable
                :tree-data="treeData"
                :replace-fields="replaceFields"
                :auto-expand-parent="autoExpandParent"
                @check="onCheck"
                v-model="checkedKeys"
                :maskClosable="false"
                :disabled="handle == 'check'"
            />
            </a-col>
            <a-col :span="18">
            <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
                <a-form-model-item label="规格名称" prop="name">
                    <a-input :disabled="handle == 'check'" v-model="rowData.name" placeholder="请输入规格名称"></a-input>
                </a-form-model-item>
                <a-form-model-item label="规格参数" prop="specValueName">
                    <a-input :disabled="handle == 'check'" v-model="rowData.specValueName" placeholder="请输入具体的属性参数，用中文 “，” 隔开"></a-input>
                </a-form-model-item>
                <a-form-model-item label="排序" prop="">
                    <a-input :disabled="handle == 'check'" v-model="rowData.sort" placeholder="请输入排序"></a-input>
                </a-form-model-item>
                <a-form-model-item label="状态" prop="flagEnable">
                    <a-switch :disabled="handle == 'check'" v-model="rowData.flagEnable" checked-children="启用" un-checked-children="禁用" default-checked />
                </a-form-model-item>
                <!-- <a-form-model-item label="是否支持配图" prop="flagImage">
                    <a-switch :disabled="handle == 'check'" v-model="rowData.flagImage" checked-children="启用" un-checked-children="禁用" default-checked />
                </a-form-model-item> -->
            </a-form-model>
            </a-col>
        </a-row>
      <div class="footer-bts" v-if="handle != 'check'">
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="toSubmit">提交</a-button>
      </div>
    </a-modal>
</template>

<script>
import { editProductBaseCategorySpec, selectByIdProductBaseCategorySpec, addProductBaseCategorySpec } from '../api/ProductBaseCategorySpecApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            loading: false,
            treeData: [],
            autoExpandParent: true,
            checkedKeys: [],
            selectedKeys: [],
            replaceFields: {
                children: 'children',
                title: 'name',
                key: 'id'
            },
            // 表单验证
            formRule: {
                name: [
                    { required: true, message: '请输入规格名称', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' }
                ],
                specValueName: [
                    { required: true, message: '请输入规格参数', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {

      // 禁用弹框
      handleCancel() {
        this.rowData = {}
        // this.typeSpecTableData = []
        // this.typeSpecList = []
        // this.categoryIds = []
        // this.brandId = []
        this.visible = false
      },
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = row
            this.checkedKeys = this.rowData.categoryId
            this.axios.get('/api/product/category/list').then(res => {
                this.treeData = res.body[0].children
            })
            if(handle == 'add') {
                this.$set(this.rowData, 'flagEnable', true)
            }
            if(handle == 'add' ||handle=='check') {
                this.$set(this.rowData, 'flagEnable', true)
            }
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                this.rowData.categoryId = this.checkedKeys
                // if(!this.checkedKeys){
                //     this.$notification.warning({ message: '请选择分类' })
                //     return
                // }
                const res = this.handle === 'add' ? await addProductBaseCategorySpec(this.rowData) : await editProductBaseCategorySpec(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        },
        onCheck(checkedKeys, info) {
            console.log("checkedKeys",checkedKeys)
            this.checkedKeys = checkedKeys
        },
    },
    created() {
        this.axios.get('/api/product/category/list?FlagEnable=true&flagDel=true').then(res => {
            this.treeData = res.body[0].children
        })
    },

}
</script>

<style lang="less" scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #E9E9E9;
}
</style>
